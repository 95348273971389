#beatTheHeatDiv {
    height: 100%;
    width: 100%;
    background-color: #EBC6AB;
    position: relative;
    z-index: 1;
    display: flex;
    gap: 2rem;
    padding-top: 2vh;
    padding-left: 1.5vw;
}

#heatDivDesktop {
    display: flex;
    gap: 1vw;
}

.heatImage {
    height: 45vh;
    width: 38vw;
    border-radius: 10px;
}

#heatDivTablet {
    display: none;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    #heatDivDesktop {
        display: none;
    }

    #heatDivTablet {
        display: block;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    #beatTheHeatDiv {
        padding-top: 1vh;
        padding-left: 1vw;
    }

    .heatImage {
        height: 30vh;
        width: 88vw;
        border-radius: 1px;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){}

/* Surface Duo */
@media only screen and (max-width:550px){}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px){
    .heatImage {
        height: 35vh;
        width: 93vw;
    }

    #beatTheHeatDiv {
        padding-top: 1vh;
        padding-left: 1vw;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){}

/* Galaxy Fold */
@media only screen and (max-width:30px){}