#marqueeDiv {
    margin-top: 2rem;
}

#marqueeDiv p {
    font-size: 2rem;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px) {}

/* iPad Air */
@media only screen and (max-width:850px) {}

/* iPad Mini */
@media only screen and (max-width:800px) {}

/* Surface Duo */
@media only screen and (max-width:550px) {}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Galaxy A51 */
@media only screen and (max-width:450px) {}

/* Pixel 5 */
@media only screen and (max-width:400px){}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px) {
    #marqueeDiv p {
        font-size: 1.5rem;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:300px) {}