#contactDiv {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    background-color: #EBC6AB;
}

#messageMobile {
    display: none;
}

#contactHeader {
    text-align: center;
    margin-top: 14vh;
    color: #230903;
}

#contactFormDiv {
    height: 65vh;
    width: 65vw;
    border: 1px solid #230903;
    border-radius: 15px;
    display: flex;
    margin-left: 17.5vw;
    background-image: linear-gradient(to right, #FFFFFF, #EBC6AB);
    box-shadow: 2px 2px 4px #230903;
}

.contactCol {
    flex: 1;
}

.contactColRight {
    padding: 2rem;
}

#contactForm {
    display: flex;
    flex-direction: column;
}

#contactFormLogo {
    width: 25vw;
    padding-top: 10vh;
}

#name {
    margin-bottom: 1rem;
}

#email {
    margin-bottom: 1rem;
}

#submitButton {
    height: 3rem;
    width: 6rem;
    margin-top: 1rem;
    background-color: #D5600E;
    color: White;
    border: none;
    border-radius: 15px;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    #contactFormDiv {
        height: 40vh;
        width: 65vw;
        margin-left: 17.5vw;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){
    #contactFormDiv {
        height: 45vh;
        width: 65vw;
        margin-left: 17.5vw;
    }

    #contactFormLogo {
        padding-top: 15vh;
    }
}

/* iPad Mini */
@media only screen and (max-width:800px){
    #contactFormDiv {
        height: 52vh;
    }

    #contactFormLogo {
        padding-top: 18vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px){
    #contactDiv {
        gap: 1rem;
    }

    #contactFormDiv {
        height: 75vh;
        width: 80vw;
        margin-left: 10vw;
    }

    #contactHeader {
        margin-top: 18vh;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px){
    .contactColLeft {
        display: none;
    }

    #contactFormLogo {
        display: none;
    }

    #contactFormDiv {
        height: 60vh;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){
    #contactDiv {
        height: 120vh;
    }

    #contactFormDiv {
        height: 80vh;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:30px){}