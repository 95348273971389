* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #230903;
    opacity: 0.9;
    box-shadow: 1px 1px 2px #D5600E;
}

.hamburgerContainer {
    display: none;
}

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1rem;
    align-items: center;
    height: 12vh;
    width: 95%;
}

#navbarLogo {
    width: 7rem;
    margin-top: -1rem;
}

.navLinks {
    display: flex;
    justify-content: space-between;
}

.links {
    display: flex;
    gap: 1.5rem;
    list-style: none;
}

.navigationLink {
    /* font-family: 'Ribeye', cursive; */
    /* font-family: 'Stoke', serif; */
    font-family: 'Wellfleet', cursive;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: bold;
    color: #F6AD79;
    letter-spacing: 0.1rem;
}

.navigationLink:hover {
    text-decoration: underline #D5600E;
    color: #D5600E;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px) {
    nav {
        opacity: 1.0;
    }

    .navbar {
        flex-direction: column;
        height: 8vh;
    }

    .links {
        margin-top: 1rem;
    }
}

/* iPad Air */
@media only screen and (max-width:850px) {
    .navbar {
        height: 9vh;
    }

    #navbarLogo {
        width: 7rem;
        margin-top: 0rem;
    }
}

/* iPad Mini */
@media only screen and (max-width:800px) {
    .navbar {
        height: 9vh;
    }

    #navbarLogo {
        width: 5rem;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px) {
    .navbar {
        height: 16vh;
    }

    .links {
        margin-top: 1.5rem;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px) {
    .navbar {
        flex-direction: row;
        height: 14vh;
    }

    .navLinks {
        display: none;
    }

    .hamburgerContainer {
        display: block;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px) {}

/* Galaxy Fold */
@media only screen and (max-width:300px) {}