#newsDiv {
    height: 100%;
    width: 100%;
    background-color: #EBC6AB;
    position: relative;
    padding: 1rem 0rem 0rem 1rem;
}

.newsRow {
    width: 100vw;
}

#newsMarqueeDiv {
    width: 100%;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){}

/* Surface Duo */
@media only screen and (max-width:550px){}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Galaxy A51 */
@media only screen and (max-width:450px){}

/* Pixel 5 */
@media only screen and (max-width:400px){
    #newsDiv {
        padding: 2rem;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){
    #newsDiv {
        padding: 2rem;
    }

    #newsHeader {
        font-size: 1.5rem;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:30px){}