* {
    padding: 0;
    margin: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
}

*::-webkit-scrollbar {
    display: none;
}

#homepageWrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
}

#homepageDiv {
    position: relative;
    padding-top: 56.25%;
    height: 0;
}

.reactPlayerHomepage {
    position: absolute;
    top: 0;
    left: 0;
}

#homePageOverlay {
    width: 100vw;
    height: 100vh;
    background-color: black;
    opacity: 0.8;
    z-index: 98;
    position: absolute;
    top: 0;
    left: 0;
}

#sslogoHomepage {
    position: absolute;
    top: 8vh;
    left: 25vw;
    width: 50vw;
    z-index: 99;
}

#weatherDiv {
    position: absolute;
    top: 30vh;
    right: -15vw;
    border-radius: 15px;
    opacity: 0.6;
}

#weatherDiv:hover {
    top: 30vh;
    right: 0vw;
    height: 62vh;
    opacity: 1.0;
}

#weatherDivAlt {
    display: none;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px) {
    #homepageWrapper {
        background-color: #230903;
    }

    #homepageDiv {
        margin-top: 8vh;
    }

    #weatherDiv {
        display: none;
    }

    #weatherDivAlt {
        display: block;
        padding: 1rem;
    }
}

/* iPad Air */
@media only screen and (max-width:850px) {
    #homepageDiv {
        margin-top: 9vh;
    }

    #weatherIcon {
        top: 41vh;
    }
}

/* iPad Mini */
@media only screen and (max-width:800px) {
    #homepageDiv {
        margin-top: 9vh;
    }

    #weatherIcon {
        top: 43vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px) {
    #homepageDiv {
        margin-top: 16vh;
    }

    #weatherIcon {
        top: 48vh;
    }

    #weatherIconImage {
        height: 4rem;
        width: 4rem;
        border-radius: 15px 0px 0px 15px;
        border: 1px solid white;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px) {
    #homepageDiv {
        margin-top: 14vh;
    }

    #weatherIcon {
        top: 32.5vh;
    }

    #weatherIconImage {
        height: 3rem;
        width: 3rem;
        border-radius: 15px 0px 0px 15px;
        border: 1px solid white;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px) {
    #weatherIcon {
        top: 38vh;
    }
}

/* Pixel 5 */
@media only screen and (max-width:400px) {
    #weatherIcon {
        top: 34vh;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:300px) {
    #weatherIcon {
        top: 31vh;
    }

    #weatherIconImage {
        height: 2rem;
        width: 2rem;
        border-radius: 12px 0px 0px 12px;
    }
}