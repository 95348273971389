#bePreparedDiv {
    background-color: #EBC6AB;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}

#trailGearImgDiv {
    height: 48vh;
    width: 48vw;
    position: absolute;
    top: 4vh;
    left: 2vw;
    z-index: 2;
}

#trailGearImg {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

#trailRatingsDiv {
    height: 25vh;
    width: 22vw;
    position: absolute;
    top: 48vh;
    left: 25vw;
    z-index: 3;
    padding: 1rem;
    border-radius: 15px;
    background-color: #FBF4EE;
}

#trailRatingsDiv h3 {
    margin-bottom: 1rem;
}

#trailRatingsDiv p {
    font-style: italic;
}

#gearDiv {
    height: 32vh;
    width: 30vw;
    position: absolute;
    top: 8vh;
    left: 50vw;
    z-index: 4;
    display: flex;
    flex-direction: column-reverse;
}

.gearIconDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.gearIconDiv p {
    padding-top: 1rem;
    padding-left: 1rem;
}

.gearDivIcon {
    width: 4rem;
}

.sunblockIcon {
    width: 2rem;
    margin-left: 1.5rem;
    margin-right: 1rem;
}

.phoneIcon {
    margin-left: .5rem;
}

.hat {
    margin-left: .75rem;
}

.boot {
    margin-left: 1rem;
}

.waterIcon {
    width: 5rem;
    margin-right: -.5rem;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    #trailGearImgDiv {
        height: 35vh;
        width: 52vw;
        top: 4vh;
        left: 4vw;
    }

    #gearDiv {
        height: 32vh;
        width: 30vw;
        top: -2vh;
        left: 56vw;
    }

    #trailRatingsDiv {
        height: 15vh;
        width: 38vw;
        top: 35vh;
        left: 30vw;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){
    #gearDiv {
        top: 2vh;
    }

    #trailRatingsDiv {
        height: 19vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px){
    .sunblockIcon {
        width: 1rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .phoneIcon {
        margin-left: .2rem;
    }

    .hat {
        margin-left: .75rem;
        width: 2rem;
    }
    
    .boot {
        margin-left: 1rem;
        width: 2rem;
    }

    #trailRatingsDiv {
        height: 24vh;
        width: 50vw;
        left: 22vw;
    }

    #trailRatingsDiv h3 {
        margin-bottom: 1rem;
        font-size: 1.25rem;
    }
    
    #trailRatingsDiv p {
        font-style: italic;
        font-size: .85rem;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px){
    #trailGearImgDiv {
        height: 28vh;
        width: 60vw;
        top: 4vh;
        left: 4vw;
    }

    #gearDiv {
        top: 2vh;
        left: 62vw;
    }

    .gearIconDiv p {
        font-size: .65rem;
    }

    .sunblockIcon {
        margin-right: .5rem;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){
    .gearIconDiv p {
        font-size: .65rem;
    }

    #trailRatingsDiv {
        height: 32vh;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:30px){}