#InfoBackgroundContainer {
    position: relative;
    height: 100vh;
    width: 100vw;
    background: transparent;
}

#arizonaBackground {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.ContentContainer {
    height: 80vh;
    width: 80vw;
    background-color: white;
    border-radius: 15px;
    position: relative;
    z-index: 50;
    margin-left: 10vw;
}

.containerOne {
    margin-top: 15vh;
}

#InfoBackgroundContainer h2 {
    position: relative;
    z-index: 50;
    color: white;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 4rem;
    font-style: italic;
}

.heatContainer {
    height: 50vh;
}

.localsContainer {
    height: 85vh;
}

.rulesContainer {
    height: 60vh;
}

.newContainer {
    height: 35vh;
    margin-bottom: 2rem;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px) {
    .ContentContainer {
        width: 90vw;
        margin-left: 5vw;
    }
    
    .containerOne {
        margin-top: 10vh;
        height: 55vh;
    }

    .heatContainer {
        height: 63vh;
    }

    .localsContainer {
        height: 102vh;
    }

    .rulesContainer {
        height: 35vh;
    }

    .newContainer {
        height: 15vh;
    }
}

/* iPad Air */
@media only screen and (max-width:850px) {
    .localsContainer {
        height: 105vh;
    }

    .rulesContainer {
        height: 40vh;
    }
}

/* iPad Mini */
@media only screen and (max-width:800px) {
    .containerOne {
        height: 58vh;
    }

    .localsContainer {
        height: 118vh;
    }

    .rulesContainer {
        height: 45vh;
    }

    .newContainer {
        height: 18vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px) {
    .containerOne {
        margin-top: 18vh;
        height: 63vh;
    }

    .heatContainer {
        height: 64vh;
    }

    .rulesContainer {
        height: 56vh;
    }

    .newContainer {
        height: 25vh;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Galaxy A51 */
@media only screen and (max-width:450px) {
    .ContentContainer {
        width: 95vw;
        margin-left: 2.5vw;
    }

    .containerOne {
        margin-top: 15vh;
    }

    #InfoBackgroundContainer h2 {
        font-size: 3rem;
    }

    .heatContainer {
        height: 74vh;
    }

    .localsContainer {
        height: 95vh;
    }
}

/* Pixel 5 */
@media only screen and (max-width:400px){}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px) {
    .containerOne {
        height: 70vh;
    }

    .rulesContainer {
        height: 65vh;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:300px) {}