#mapPageWrapper {
    position: relative;
}

#mapsBackgroundDivTop {
    height: 50vh;
    background-image: linear-gradient(#EBC6AB,#F6AD79);
}

.custom-shape-divider-top-1665200598 {
    position: absolute;
    top: 50vh;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1665200598 svg {
    position: relative;
    display: block;
    width: calc(167% + 1.3px);
    height: 181px;
}

.custom-shape-divider-top-1665200598 .shape-fill {
    fill: #F6AD79;
}

#mapsBackgroundDivBottom {
    height: 50vh;
    background-color: #EBC6AB;
}

#parkLocationDiv {
    position: absolute;
    top: 10vh;
    left: 0vw;
    height: 100vh;
    width: 100vw;
    z-index: 5;
}

#desktopMapContainer {
    position: relative;
    height: 80vh;
    width: 70vw;
    background-color: #A07456;
    border-radius: 10px;
    box-shadow: 3px 3px 4px 2px #230903;
    margin-top: 4vh;
    margin-left: 15vw;
    display: flex;
    flex-direction: column;
}

#desktopMapContainer h2 {
    /* font-family: 'Special Elite', cursive; */
    font-family: 'Stoke', serif;
    color: white;
    margin-left: 3rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.desktopIframe {
    margin-left: 6vw;
    border-radius: 15px;
}

.tabletIframe {
    display: none;
}

.tabletIframeTwo {
    display: none;
}

.tabletIframeThree {
    display: none;
}

.mobileIframe {
    display: none;
}

.mobileIframeTwo {
    display: none;
}

#trailMapsDiv {
    background-image: linear-gradient(#EBC6AB,#F6AD79);
    height: 110vh;
    width: 100vw;
    position: relative;
}

#trailMapsContainer {
    position: absolute;
    top: 0vh;
    left: 15vw;
    height: 105vh;
    width: 70vw;
    background-color: #A07456;
    border-radius: 10px;
    box-shadow: 3px 3px 4px 2px #230903;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#trailMapsContainer h2 {
    font-family: 'Stoke', serif;
    color: white;
    font-size: 2rem;
    margin-bottom: 2rem;
}

#trailMapsContainer p {
    margin-left: 2.5rem;
}

#trailMap {
    width: 60vw;
    margin-left: 2.5vw;
    border-radius: 10px;
}

#trailMapLink {
    color: white;
    margin-left: 2.5rem;
    font-size: 1.5rem;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    #desktopMapContainer {
        height: 50vh;
        width: 90vw;
        margin-top: 4vh;
        margin-left: 5vw;
    }

    .desktopIframe {
        display: none;
    }
    
    .tabletIframe {
        display: block;
        margin-left: 6vw;
        border-radius: 15px;
    }

    #mapsBackgroundDivBottom {
        height: 20vh;
        background-color: #EBC6AB;
    }

    #trailMapsContainer {
        left: 5vw;
        height: 50vh;
        width: 90vw;
    }

    #trailMapsDiv {
        background-image: linear-gradient(#EBC6AB,#F6AD79);
        height: 60vh;
        margin-top: -1rem;
    }

    #trailMap {
        width: 70vw;
        margin-left: 6vw;
        border-radius: 10px;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){
    .tabletIframe {
        display: none;
    }

    .tabletIframeTwo {
        display: block;
        margin-left: 8vw;
        margin-top: 1rem;
    }
}

/* iPad Mini */
@media only screen and (max-width:800px){
    #desktopMapContainer {
        height: 58vh;
    }

    #mapsBackgroundDivBottom {
        height: 28vh;
    }

    .tabletIframeTwo {
        margin-left: 6vw;
    }

    #trailMapsDiv {
        height: 70vh;
    }

    #trailMapsContainer {
        height: 55vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px){
    #desktopMapContainer {
        height: 80vh;
        width: 98vw;
        margin-left: 1vw;
        margin-top: 7vh;
    }

    .tabletIframeTwo {
        display: none;
    }

    .tabletIframeThree {
        display: block;
        margin-left: 3vw;
    }

    #mapsBackgroundDivTop {
        height: 60vh;
    }

    #mapsBackgroundDivBottom {
        height: 42vh;
    }

    #trailMapsContainer {
        width: 98vw;
        height: 68vh;
        left: 1vw;
    }

    #trailMap {
        width: 85vw;
        margin-left: 1vw;
        border-radius: 10px;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Galaxy A51 */
@media only screen and (max-width:450px){
    .tabletIframeThree {
        display: none;
    }

    .mobileIframe {
        display: block;
        margin-left: 3vw;
        border-radius: 12px;
    }

    #desktopMapContainer {
        height: 65vh;
    }

    #mapsBackgroundDivBottom {
        height: 26vh;
    }
}

/* Pixel 5 */
@media only screen and (max-width:395px){
    .mobileIframe {
        display: none;
    }

    .mobileIframeTwo {
        display: block;
        margin-left: 5vw;
        border-radius: 12px;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){
    .mobileIframeTwo {
        margin-left: 3vw;
        border-radius: 12px;
    }

    #desktopMapContainer {
        height: 72vh;
    }

    #mapsBackgroundDivBottom {
        height: 35vh;
    }

    #desktopMapContainer h2 {
        font-size: 1.5rem;
    }

    #trailMapsContainer h2 {
        font-size: 1.5rem;
    }

    #trailMapsContainer {
        width: 98vw;
        height: 70vh;
        left: 1vw;
    }

    #trailMapsDiv {
        height: 76vh;
    }

    #trailMap {
        width: 85vw;
        margin-left: -1vw;
        border-radius: 10px;
    }

    #trailMapLink {
        color: white;
        margin-left: 2.5rem;
        font-size: 1rem;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:30px){}