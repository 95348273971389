html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.burgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 108vh;
    width: 102vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    gap: 1rem;
    animation-name: slidein;
    animation-duration: .75s;
    background-color: #A07456;
    color: White;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.burgerLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: -3.5rem;
}

.burgerMenuNavlink {
    text-decoration: none;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: white;
}

.burgerMenuNavlink:hover {
    color: white;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){}

/* Surface Duo */
@media only screen and (max-width:550px){}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px){}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){}

/* Galaxy Fold */
@media only screen and (max-width:30px){}