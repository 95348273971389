#localsDiv {
    height: 100%;
    width: 100%;
    background-color: #EBC6AB;
    display: flex;
    flex-direction: column;
}

#localsCarouselDiv {
    display: flex;
    gap: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
}

.carouselContainer {
    height: 60vh;
    width: 38vw;
}

#localsCarouselLabels {
    display: flex;
    padding-top: 2rem;
    width: 100%;
    justify-content: center;
    gap: 22vw;
}

.carouselItem {
    height: 100%;
    width: 100%;
}

.localSlide {
    height: 100%;
    width: 100%;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    #localsCarouselDiv {
        display: flex;
        flex-direction: column;
    }

    #localsCarouselLabels {
        display: none;
    }

    .carouselContainer {
        height: 50vh;
        width: 87vw;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){
    .carouselContainer {
        height: 58vh;
    }
}

/* Surface Duo */
@media only screen and (max-width:550px){
    .carouselContainer {
        width: 85vw;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Pixel 5 / Galaxy A51 */
@media only screen and (max-width:450px){
    #localsCarouselDiv {
        padding-left: 2.5vw;
        gap: 0rem;
    }

    .carouselContainer {
        height: 45vh;
        width: 90vw;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){}

/* Galaxy Fold */
@media only screen and (max-width:30px){}