#rulesDiv {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #EBC6AB;
    display: flex;
}

.rulesCol {
    flex: 1;
    padding-top: 15vh;
}

.rulesLi {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    padding-left: 4rem;
}

/* -----------------------------------------------------
Tablet and Mobile versions
----------------------------------------------------- */

/* ----------
Tablets
---------- */

/* Surface Pro 7 */
@media only screen and (max-width:915px){
    .rulesCol {
        flex: 1;
        padding-top: 5vh;
    }

    .rulesLi {
        padding-left: 2rem;
    }
}

/* iPad Air */
@media only screen and (max-width:850px){}

/* iPad Mini */
@media only screen and (max-width:800px){}

/* Surface Duo */
@media only screen and (max-width:550px){
    .rulesLi {
        font-size: 1rem;
        padding-left: 0rem;
    }
}

/* ----------
Mobile
---------- */

/* iPhone XR / Galaxy S20 / Galaxy A51 */
@media only screen and (max-width:450px){

}

/* Pixel 5 */
@media only screen and (max-width:400px){
    #rulesHeaderDiv {
        left: 25vw;
    }

    .rulesLi {
        padding-left: 1rem;
        font-size: .85rem;
    }
}

/* iPhone SE / iPhone 12 Pro / Galaxy S8+ */
@media only screen and (max-width:390px){
    #rulesHeaderDiv {
        left: 25vw;
    }

    .rulesLi {
        padding-left: 1rem;
        font-size: .85rem;
    }
}

/* Galaxy Fold */
@media only screen and (max-width:30px){}